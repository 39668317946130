import React, { useEffect } from "react";
import styled from "styled-components";
import { ScrollLocky } from "react-scroll-locky";
import { mainWhite, mainBlack, screen } from "../variables";
import { motion, AnimatePresence } from "framer-motion";
import { GatsbyImage } from "gatsby-plugin-image";
// motion framer animation props value
const variantsContainer = {
  open: { opacity: 1 },
  close: { opacity: 0 },
};

const variantsList = {
  open: {
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.07,
    },
  },
  close: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const variantsItem = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  close: {
    y: 20,
    opacity: 0.5,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const Wrapper = styled(motion.div)`
  background: rgba(33, 39, 34, 0.99);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;

  .inner-wrapper {
    background: ${mainWhite};

    &--modal {
      display: flex;
      padding: 50px 24px;
      align-items: center;
      position: relative;
      width: calc(100% - 24px);
      flex-direction: column;
      max-height: calc(100vh - 24px);
      overflow-y: scroll;
      @media ${screen.xsmall} {
        flex-direction: row;
        width: 100%;
        padding: 60px 50px;
        margin: 0 50px;
      }

      @media ${screen.small} {
        height: auto;
      }

      .col {
        &--img {
          text-align: center;
          max-width: 280px;
          width: 100%;
          .img-subwrapper {
            max-width: 120px;
            margin: 0 auto;
            @media ${screen.xsmall} {
              max-width: 200px;
            }
          }

          img {
            display: block;
            width: 100%;
            border-radius: 50%;
            @media ${screen.xsmall} {
            }
          }

          .name {
            font-size: 1.1rem;
            font-weight: 500;
            margin: 10px 0 0 0;
            @media ${screen.xsmall} {
              font-size: 1.4rem;
              margin: 20px 0 0 0;
            }
          }

          .position {
            font-size: 0.95rem;
            margin: 10px 0 20px 0;
            max-width: 300px;
            @media ${screen.xsmall} {
              font-size: 1.1rem;
              margin: 12px 0 24px 0;
            }
          }
        }

        &--txt {
          @media ${screen.xsmall} {
            padding: 0 70px 0 60px;
          }

          .bio {
            max-width: 900px;
            p {
              font-weight: 300;
              font-size: 1rem;
              margin: 0 0 16px 0;
              @media ${screen.xsmall} {
                font-size: 1.1rem;
              }
              &:last-child {
                margin: 0;
              }
            }

            a {
              color: ${mainBlack};
              text-decoration: underline;
            }

            ul {
              padding: 0 0 0 32px;
              margin: 0 0 16px 0;

              li {
                position: relative;
                line-height: 1.65;
                font-weight: 300;
                font-size: 1rem;
                @media ${screen.xsmall} {
                  font-size: 1.1rem;
                }

                &::after {
                  content: "-";
                  left: -10px;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }

            ol {
              padding: 0 0 0 40px;
              margin: 0 0 16px 0;

              li {
                list-style-type: decimal;
                line-height: 1.65;
                font-weight: 300;
                font-size: 1rem;
                @media ${screen.xsmall} {
                  font-size: 1.1rem;
                }
              }
            }

            strong {
              font-weight: 500;
            }
          }
        }
      }
    }

    .close-btn {
      all: unset;
      position: absolute;
      cursor: pointer;
      font-size: 1rem;
      font-weight: 700;
      right: 22px;
      top: 20px;
    }
  }
`;

const MemberModal = ({ speaker, setSpeaker }) => {
  useEffect(() => {
    const userClick = (e) => {
      if (e.target.id === "modal-speaker") {
        setSpeaker(null);
      }
    };

    window.addEventListener("click", userClick);

    return () => {
      window.removeEventListener("click", userClick);
    };
  }, []);

  return (
    <ScrollLocky isolation={false} enabled={speaker}>
      <AnimatePresence>
        {speaker && (
          <Wrapper
            initial="close"
            animate={speaker ? "open" : "close"}
            variants={variantsContainer}
            exit={{ opacity: 0 }}
            id="modal-speaker"
          >
            <motion.div
              className="inner-wrapper inner-wrapper--modal"
              variants={variantsList}
            >
              <motion.div variants={variantsItem} className="col col--img">
                <div className="img-subwrapper">
                  <GatsbyImage
                    image={speaker.image}
                    alt={speaker.name || "Photo"}
                  />
                </div>
                <h6 className="name">{speaker.name}</h6>
                <p className="position">{speaker.position}</p>
              </motion.div>
              <motion.div variants={variantsItem} className="col col--txt">
                <div
                  className="bio"
                  dangerouslySetInnerHTML={{ __html: speaker.bio }}
                />
              </motion.div>

              <motion.button
                whileTap={{ scale: 0.965 }}
                className="close-btn"
                onClick={() => {
                  setSpeaker(null);
                }}
              >
                CLOSE
              </motion.button>
            </motion.div>
          </Wrapper>
        )}
      </AnimatePresence>
    </ScrollLocky>
  );
};

export default MemberModal;
