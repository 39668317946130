import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Logo from "../images/svg/logo.svg";
import {
  mainWhite,
  mainBlack,
  mainFont,
  mainBlue,
  subFont,
  lightBlue,
  screen,
} from "../components/variables";
import { Link as SpyLink } from "react-scroll";
import Seo from "../components/seo";
import styled from "styled-components";
import MemberModal from "../components/member-modal";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import Line from "../images/svg/line.svg";
import lineAsBg from "../images/line.png";
import { FaTwitter, FaLinkedin } from "react-icons/fa";

const Wrapper = styled.div`
  background: ${lightBlue};

  .inner-wrapper {
    &--navbar {
      position: relative;
      height: 96px;
      @media ${screen.xsmall} {
        height: 140px;
      }
    }

    &--intro {
      display: flex;
      flex-direction: column;
      @media ${screen.small} {
        flex-direction: row;
      }
    }

    &--join {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media ${screen.xsmall} {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &--register {
      display: flex;
      flex-direction: column;
      @media ${screen.xsmall} {
        flex-direction: row;
      }
    }

    &--footer {
      display: flex;
      flex-direction: column;
      @media ${screen.small} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .navbar {
    background: ${mainWhite};

    .brand {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 24px;
      @media ${screen.xsmall} {
        left: 48px;
      }
      @media ${screen.medium} {
        left: 0;
      }

      svg {
        width: 170px;
        @media ${screen.xsmall} {
          width: 200px;
        }
        @media ${screen.medium} {
          width: 230px;
        }
      }
    }

    .partner {
      position: absolute;
      max-width: 52px;
      top: 50%;
      transform: translateY(-50%);
      right: 24px;
      @media ${screen.xsmall} {
        right: 48px;
        max-width: 80px;
      }
      @media ${screen.medium} {
        right: 0;
        max-width: 87px;
      }

      img {
        display: block;
      }
    }
  }

  .hero {
    background: ${lightBlue};
    color: ${mainWhite};
    padding: 60px 0;

    .heading {
      font-size: 2.2rem;
      font-weight: 700;
      max-width: 960px;
      @media ${screen.xsmall} {
        font-size: 2.9rem;
        white-space: pre-wrap;
      }
      @media ${screen.small} {
        font-size: 3.3rem;
      }
    }

    .row {
      display: flex;
      margin: 30px 0 0 0;

      .col {
        &--left {
          min-width: 140px;
          @media ${screen.xsmall} {
            min-width: none;
            width: 17%;
          }
          @media ${screen.small} {
            width: 12%;
          }
        }

        span {
          font-size: 1rem;
          font-weight: 500;
          @media ${screen.xsmall} {
            font-size: 1.1rem;
          }
        }

        p,
        a {
          font-size: 1rem;
          font-weight: 300;
          white-space: pre-wrap;
          max-width: 830px;
          @media ${screen.xsmall} {
            font-size: 1.1rem;
          }
        }

        strong {
          font-weight: 700;
        }

        a {
          color: ${mainWhite};
          line-height: 1.55;
          @media ${screen.xsmall} {
            white-space: pre-wrap;
          }
        }
      }
    }

    .cta-btn {
      background: ${mainBlack};
      color: ${mainWhite};
      cursor: pointer;
      font-weight: 700;
      font-size: 1.1rem;
      padding: 10px 27px;
      display: table;
      margin: 40px 0 0 0;
      text-transform: uppercase;
      border: 2px ${mainBlack} solid;
      @media ${screen.xsmall} {
        font-size: 1.2rem;
      }
      @media ${screen.withCursor} {
        &:hover {
          background: ${mainWhite};
          color: ${mainBlack};
          border: 2px ${mainWhite} solid;
        }
      }
    }
  }

  .text-image {
    background: ${mainWhite};
    padding: 70px 0;
    @media ${screen.xsmall} {
      padding: 100px 0 100px 0;
    }

    &--first {
      @media ${screen.small} {
        border-radius: 0 200px 0 0;
      }
    }

    .heading {
      font-size: 1.7rem;
      font-weight: 700;
      margin: 0 0 60px 0;
      color: ${mainBlue};
      text-align: center;
      @media ${screen.xsmall} {
        font-size: 2.175rem;
      }
    }

    .txt {
      order: 1;
      margin: 20px 0 0 0;
      @media ${screen.small} {
        width: 47%;
        order: 0;
        margin: 0;
      }

      .description {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-weight: 300;
          margin: 0 0 22px 0;
          line-height: 1.4;
        }

        h1 {
          font-size: 1.6rem;
        }

        h2 {
          font-size: 1.5rem;
        }

        h3 {
          font-size: 1.45rem;
        }

        h4 {
          font-size: 1.4rem;
        }

        h5 {
          font-size: 1.35rem;
        }

        h6 {
          font-size: 1.3rem;
        }

        p {
          font-weight: 300;
          font-size: 1rem;
          margin: 0 0 16px 0;
          @media ${screen.xsmall} {
            font-size: 1.1rem;
          }

          &:last-child {
            margin: 0;
          }
        }

        a {
          color: ${mainBlack};
          text-decoration: underline;
        }

        ul {
          padding: 0 0 0 20px;
          margin: 0 0 16px 0;
          @media ${screen.xsmall} {
            padding: 0 0 0 32px;
          }

          li {
            position: relative;
            line-height: 1.65;
            font-weight: 300;
            font-size: 1rem;
            @media ${screen.xsmall} {
              font-size: 1.1rem;
            }

            &::after {
              content: "-";
              left: -10px;
              position: absolute;
              top: 0;
            }
          }
        }

        ol {
          padding: 0 0 0 32px;
          margin: 0 0 16px 0;
          @media ${screen.xsmall} {
            padding: 0 0 0 40px;
          }

          li {
            list-style-type: decimal;
            line-height: 1.65;
            font-weight: 300;
            font-size: 1rem;
            @media ${screen.xsmall} {
              font-size: 1.1rem;
            }
          }
        }

        strong {
          font-weight: 500;
        }
      }

      .cta-btn {
        display: table;
        font-family: ${subFont};
        font-size: 1.93rem;
        color: ${mainBlack};
        position: relative;
        margin: 35px 0 0 0;
        cursor: pointer;
        @media ${screen.withCursor} {
          &:hover {
            color: #ffc584;
          }
        }

        .line {
          display: block;
          width: 174px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -15px;

          svg {
            display: block;

            .fill {
              fill: #ffc584;
            }
          }
        }
      }
    }

    .img {
      margin: 0 0 20px 0;
      @media ${screen.xsmall} {
        margin: 0 0 30px 0;
      }
      @media ${screen.small} {
        width: 53%;
        padding: 0 70px 0 70px;
        margin: 0;
      }
      @media ${screen.large} {
        padding: 0 70px 0 100px;
      }

      img {
        display: block;
        width: 100%;
      }

      .name {
        font-size: 0.95rem;
        font-weight: 500;
        margin: 20px 0 0 0;
        line-height: 1.4;
        @media ${screen.xsmall} {
          font-size: 1.4rem;
        }
      }

      .position {
        font-size: 0.8rem;
        margin: 12px 0 24px 0;
        @media ${screen.xsmall} {
          font-size: 1.1rem;
        }
      }

      .btn {
        all: unset;
        font-family: ${subFont};
        font-size: 1.2rem;
        position: relative;
        cursor: pointer;
        transition: all 0.2s ease-out;
        @media ${screen.xsmall} {
          font-size: 1.375rem;
        }
        @media ${screen.withCursor} {
          &:hover {
            color: #b3edbe;
          }
        }

        .line {
          display: block;
          width: 82px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -11px;
          @media ${screen.xsmall} {
            width: 92px;
            bottom: -15px;
          }
        }
      }
    }
  }

  .speakers {
    background: ${mainWhite};
    text-align: center;
    padding: 70px 0;
    @media ${screen.xsmall} {
      padding: 100px 0 100px 0;
    }

    .speaker {
      margin: 0 0 50px 0;
      &:last-child {
        margin: 0;
      }
      @media ${screen.small} {
        padding: 0 20px;
      }
      @media ${screen.large} {
        padding: 0 55px;
      }

      .heading {
        font-size: 1.7rem;
        font-weight: 700;
        margin: 0 0 60px 0;
        color: ${mainBlue};
        @media ${screen.xsmall} {
          font-size: 2.175rem;
        }
      }

      .speaker-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        &__each {
          width: 50%;
          margin: 0 0 60px 0;
          @media ${screen.xsmall} {
            margin: 0 0 70px 0;
          }

          @media ${screen.small} {
            width: 33.33%;
            margin: 0 0 60px 0;
          }

          .img {
            display: block;
            border-radius: 50%;
            width: 100%;
            max-width: 115px;
            margin: 0 auto;
            cursor: pointer;
            @media ${screen.xsmall} {
              max-width: 194px;
            }
          }
          .name {
            font-size: 0.95rem;
            font-weight: 500;
            margin: 20px 0 0 0;
            line-height: 1.4;
            @media ${screen.xsmall} {
              font-size: 1.4rem;
            }
          }

          .position {
            font-size: 0.8rem;
            margin: 12px auto 24px auto;
            max-width: 300px;
            @media ${screen.xsmall} {
              font-size: 1.1rem;
            }
          }

          .btn {
            all: unset;
            font-family: ${subFont};
            font-size: 1.2rem;
            position: relative;
            cursor: pointer;
            transition: all 0.2s ease-out;
            @media ${screen.xsmall} {
              font-size: 1.375rem;
            }
            @media ${screen.withCursor} {
              &:hover {
                color: #b3edbe;
              }
            }

            .line {
              display: block;
              width: 82px;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              bottom: -11px;
              @media ${screen.xsmall} {
                width: 92px;
                bottom: -15px;
              }
            }
          }
        }
      }
    }
  }

  .join {
    background: ${mainBlue};
    color: ${mainWhite};
    padding: 50px 0;
    @media ${screen.xsmall} {
      padding: 40px 0;
    }

    .heading {
      font-size: 1.25rem;
      font-weight: 500;
      max-width: 950px;
      line-height: 1.45;
      padding: 0 50px 0 0;
      @media ${screen.xsmall} {
        font-size: 1.4rem;
      }
    }

    .cta-btn {
      background: ${mainBlue};
      color: ${mainWhite};
      font-weight: 700;
      font-size: 1.1rem;
      padding: 10px 27px;
      display: table;
      text-transform: uppercase;
      border: 2px ${mainWhite} solid;
      cursor: pointer;
      margin: 20px 0 0 0;
      @media ${screen.xsmall} {
        white-space: pre;
        margin: 0;
        font-size: 1.2rem;
      }
      @media ${screen.withCursor} {
        &:hover {
          background: ${mainWhite};
          color: ${mainBlue};
        }
      }
    }
  }

  .register {
    background: #f7f7f7;
    padding: 50px 0 0 0;
    @media ${screen.xsmall} {
      padding: 100px 0 0 0;
    }

    .txt {
      @media ${screen.xsmall} {
        width: 37%;
        padding: 0 60px 0 0;
      }

      .heading {
        font-size: 1.7rem;
        font-weight: 700;
        margin: 0 0 40px 0;
        line-height: 1.35;
        @media ${screen.xsmall} {
          font-size: 2.175rem;
          margin: 0;
        }
      }
    }

    .cognito {
      font-family: ${mainFont};
      @media ${screen.xsmall} {
        width: 63%;
      }

      form {
        .c-forms-form {
          .c-forms-heading {
            display: none;
            .c-forms-form-title {
            }
          }

          .c-forms-template {
            .c-forms-form-main {
              .c-field {
                .c-label {
                  font-size: 1rem;
                  font-weight: 500;
                  @media ${screen.xsmall} {
                    font-size: 1.1rem;
                  }
                  label {
                    font-size: 1rem;
                    font-weight: 500;
                    @media ${screen.xsmall} {
                      font-size: 1.1rem;
                    }
                  }
                }

                .c-editor {
                  input {
                    border: 0;
                    font-size: 16px;
                    padding: 10px 14px;
                    margin: 6px 0 10px 0;
                    border-radius: 0;
                    outline: none;
                    @media ${screen.xsmall} {
                      font-size: 1.1rem;
                    }

                    &::placeholder {
                      font-weight: 300;
                    }
                  }

                  textarea {
                    border: 0;
                    font-size: 16px;
                    padding: 10px 14px;
                    margin: 6px 0 5px 0;
                    resize: none;
                    border-radius: 0;
                    outline: none;
                    @media ${screen.xsmall} {
                      font-size: 1.1rem;
                    }
                  }

                  .c-dropdown {
                    select {
                      width: 100%;
                      padding: 10px 14px;
                      outline: none;
                      border: 0;
                      border-radius: none;
                      margin: 6px 0 10px 0;
                      cursor: pointer;
                      border-right: 12px solid transparent;
                    }
                  }
                }

                .c-validation {
                  background: #ffc584;
                  max-width: max-content;
                }
              }

              .c-yesno-checkbox {
                display: flex;

                label {
                  span {
                    display: none;
                  }
                }

                p {
                  font-weight: 300;
                  margin: -2px 0 15px -22px;
                  font-size: 0.8rem;
                  max-width: 500px;
                  @media ${screen.xsmall} {
                    font-size: 0.88rem;
                  }
                }
              }
            }
          }

          .c-button-section {
            display: flex;
            justify-content: flex-start;
            overflow: visible;
            @media ${screen.xsmall} {
              justify-content: flex-end;
            }

            .c-button {
              all: unset;
              display: table;
              font-family: ${subFont};
              font-size: 1.93rem;
              color: ${mainBlack};
              position: relative;
              cursor: pointer;
              transition: all 0.2s ease-out;
              overflow: visible;
              line-height: 1.5;
              @media ${screen.withCursor} {
                &:hover {
                  color: #ffc584;
                }
              }

              &:after {
                content: "";
                height: 20px;
                width: 174px;
                display: block;
                position: absolute;
                left: 50%;
                bottom: -15px;
                transform: translateX(-50%);
                background-image: url(${lineAsBg});
                background-size: 174px;
                background-repeat: no-repeat;
                background-position: center;
              }
            }
          }
        }
      }
    }

    #footer {
      background: ${mainWhite};
      margin: 100px 0 0 0;
      padding: 50px 0 50px 0;
      @media ${screen.xsmall} {
        padding: 70px 0 60px 0;
      }
      @media ${screen.small} {
        border-radius: 200px 0 0 0;
        padding: 70px 0 60px 70px;
      }

      .col {
        &--left {
          display: flex;
          align-items: center;
          flex-direction: column;
          @media ${screen.xsmall} {
            flex-direction: row;
          }

          .brand {
            margin: 0;
            @media ${screen.xsmall} {
              margin: 0 25px 0 0;
            }

            svg {
              width: 160px;
              @media ${screen.xsmall} {
                width: 200px;
              }
              @media ${screen.medium} {
                width: 210px;
              }
            }
          }

          .social {
            display: flex;
            margin: 20px 0;
            @media ${screen.xsmall} {
              margin: 0;
            }

            .social-icon {
              color: ${lightBlue};
              font-size: 1.63rem;
              margin: 0 11px;
              @media ${screen.xsmall} {
                font-size: 1.93rem;
                margin: 0 0 0 30px;
              }
              @media ${screen.medium} {
                font-size: 1.83rem;
                margin: 0 0 0 20px;
              }

              @media ${screen.withCursor} {
                &:hover {
                  color: ${mainBlue};
                }
              }
            }
          }
        }

        &--right {
          display: flex;
          align-items: center;
          flex-direction: column;
          @media ${screen.xsmall} {
            flex-direction: row;
            margin: 25px 0 0 0;
          }
          @media ${screen.small} {
            margin: 0;
          }

          .blob-txt {
            max-width: 850px;
            padding: 0 24px;
            text-align: center;
            @media ${screen.xsmall} {
              text-align: left;
            }
            @media ${screen.small} {
              padding: 0 0 0 60px;
            }
            @media ${screen.large} {
              padding: 0 0 0 50px;
            }

            p {
              font-weight: 300;
              font-size: 0.9rem;
              @media ${screen.large} {
                font-size: 0.95rem;
              }
            }

            a {
              color: ${mainBlack};
              text-decoration: underline;

              &:last-child {
                color: ${lightBlue};
                font-weight: 500;
                text-decoration: none;
              }
            }
          }

          .partner {
            max-width: 52px;
            display: block;
            margin: 22px 0 0 0;
            @media ${screen.xsmall} {
              margin: 0 0 0 40px;
              max-width: 65px;
            }
            @media ${screen.medium} {
              max-width: 76px;
              margin: 0 0 0 50px;
            }
            @media ${screen.large} {
              margin: 0 0 0 80px;
            }

            img {
              display: block;
            }
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const [isBrowser, setIsBrowser] = useState(false);

  const [speaker, setSpeaker] = useState(null);

  useEffect(() => {
    setIsBrowser(typeof window !== "undefined");
  }, [speaker]);

  const {
    hero_event_title,
    hero_event_date,
    hero_event_time,
    hero_event_location,
    hero_event_google_map_url,
    hero_cta_label,
    cta_description,
    cta_cta_label,
    form_heading,
    title_tag,
    meta_description,
    footer_text,
    body,
  } = data.content.data;

  // insert cognitoforms script
  useEffect(() => {
    const cognitoForm = document.getElementById("cognito-form");
    const runScript = document.createElement("script");
    runScript.innerHTML = `Cognito.load("forms", { id: "1" });`;
    cognitoForm &&
      cognitoForm.children.length === 0 &&
      cognitoForm.appendChild(runScript);
  });

  const renderBlock = body.map((item, index) => {
    if (item.slice_type === "text_image") {
      return (
        <section
          style={{ background: item.primary.background_colour }}
          className={
            index === 0 ? "text-image text-image--first" : "text-image"
          }
          key={item.id}
        >
          {item.primary.heading.text && (
            <h3 className="heading" data-sal="fade" data-sal-duration="500">
              {item.primary.heading.text}
            </h3>
          )}

          <div className="inner-wrapper inner-wrapper--intro">
            <div className="txt" data-sal="fade" data-sal-duration="500">
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: item.primary.description.html,
                }}
              />

              {item.primary.cta_label && (
                <SpyLink className="cta-btn" smooth={true} to="register-form">
                  {item.primary.cta_label}
                  <span className="line">
                    <Line />
                  </span>
                </SpyLink>
              )}
            </div>
            <div
              className="img"
              style={{
                cursor: item.primary.bio.html.length > 8 ? "pointer" : "auto",
                pointerEvents:
                  item.primary.bio.html.length > 8 ? "all" : "none",
              }}
              onClick={() => {
                setSpeaker({
                  name: item.primary.name.text,
                  position: item.primary.position_title,
                  image: item.primary.lightbox_image.gatsbyImageData,
                  bio: item.primary.bio.html,
                });
              }}
              data-sal="fade"
              data-sal-duration="500"
            >
              <GatsbyImage
                image={item.primary.featured_image.gatsbyImageData}
                alt={item.primary.featured_image.alt || "Feautured"}
              />
              {item.primary.name.text && (
                <h5 className="name">{item.primary.name.text}</h5>
              )}

              {item.primary.position_title && (
                <p className="position">{item.primary.position_title}</p>
              )}

              {item.primary.bio.html.length > 8 && (
                <button className="btn">
                  Read Bio
                  <span className="line">
                    <Line />
                  </span>
                </button>
              )}
            </div>
          </div>
        </section>
      );
    }

    if (item.slice_type === "speakers") {
      return (
        <section
          key={item.id}
          className="speakers"
          style={{ background: item.primary.background_colour }}
        >
          <div className="inner-wrapper">
            <div className="speaker">
              <h3 className="heading" data-sal="fade" data-sal-duration="500">
                {item.primary.heading.text}
              </h3>
              <ul className="speaker-list">
                {item.items.map((person, i) => (
                  <li
                    data-sal="fade"
                    data-sal-duration="500"
                    className="speaker-list__each"
                    key={person + i}
                    onClick={() => {
                      setSpeaker({
                        name: person.name.text,
                        position: person.position_title,
                        image: person.image.thumbnails.cropped.gatsbyImageData,
                        bio: person.bio.html,
                      });
                    }}
                  >
                    <GatsbyImage
                      className="img"
                      image={person.image.thumbnails.cropped.gatsbyImageData}
                      alt={person.image.alt || "Photo"}
                    />
                    <h5 className="name">{person.name.text}</h5>
                    <p className="position">{person.position_title}</p>
                    <button className="btn">
                      Read Bio
                      <span className="line">
                        <Line />
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      );
    }
  });

  return (
    <Layout>
      <Seo title={title_tag} description={meta_description} />
      <Wrapper>
        <nav className="navbar">
          <div className="inner-wrapper inner-wrapper--navbar">
            <a
              href="https://www.8squad.com.au"
              target="_blank"
              className="brand"
            >
              <Logo />
            </a>
            <a
              className="partner"
              href="https://www.salesforce.com/au"
              target="_blank"
            >
              <StaticImage
                src="../images/salesforce.png"
                alt="partner"
                quality={100}
              />
            </a>
          </div>
        </nav>

        <header className="hero">
          <div className="inner-wrapper">
            <h1 className="heading">{hero_event_title}</h1>
            <div className="row">
              <div className="col col--left">
                <span>Date</span>
              </div>
              <div className="col">
                <div
                  dangerouslySetInnerHTML={{ __html: hero_event_date.html }}
                />

                <p>{hero_event_time}</p>
              </div>
            </div>
            <div className="row">
              <div className="col col--left">
                <span>Join Online</span>
              </div>
              <div className="col">
                <a href={hero_event_google_map_url} target="_blank">
                  {hero_event_location}
                </a>
              </div>
            </div>

            <SpyLink className="cta-btn" smooth={true} to="register-form">
              {hero_cta_label || "REGISTER NOW!"}
            </SpyLink>
          </div>
        </header>

        {renderBlock}

        <section className="join">
          <div
            className="inner-wrapper inner-wrapper--join"
            data-sal="fade"
            data-sal-duration="500"
          >
            <h6 className="heading">{cta_description}</h6>
            <SpyLink className="cta-btn" smooth={true} to="register-form">
              {cta_cta_label}
            </SpyLink>
          </div>
        </section>

        <div id="register-form" className="register">
          <div className="form">
            <div className="inner-wrapper inner-wrapper--register">
              <div className="txt" data-sal="fade" data-sal-duration="500">
                <h3 className="heading">{form_heading.text}</h3>
              </div>
              <div className="cognito" data-sal="fade" data-sal-duration="500">
                {isBrowser && <div id="cognito-form" className="cognito"></div>}
              </div>
            </div>
          </div>
          <footer id="footer">
            <div
              className="inner-wrapper inner-wrapper--footer"
              data-sal="fade"
              data-sal-duration="500"
            >
              <div className="col col--left">
                <a
                  className="brand"
                  href="https://www.8squad.com.au"
                  target="_blank"
                >
                  <Logo />
                </a>
                <div className="social">
                  <a
                    className="social-icon"
                    href="https://twitter.com/8SQUAD2"
                    target="_blank"
                  >
                    <FaTwitter />
                  </a>
                  <a
                    className="social-icon"
                    href="https://www.linkedin.com/company/8squad/"
                    target="_blank"
                  >
                    <FaLinkedin />
                  </a>
                </div>
              </div>
              <div className="col col--right">
                <div
                  className="blob-txt"
                  dangerouslySetInnerHTML={{ __html: footer_text.html }}
                />
                <a
                  className="partner"
                  href="https://www.salesforce.com/au"
                  target="_blank"
                >
                  <StaticImage
                    src="../images/salesforce.png"
                    alt="partner"
                    quality={100}
                  />
                </a>
              </div>
            </div>
          </footer>
        </div>
      </Wrapper>

      <MemberModal speaker={speaker} setSpeaker={setSpeaker} />
    </Layout>
  );
};

export default IndexPage;

export const dataQuery = graphql`
  {
    content: prismicHo {
      data {
        hero_event_title
        hero_event_date {
          html
        }
        hero_event_time
        hero_event_location
        hero_event_google_map_url
        hero_cta_label
        cta_description
        cta_cta_label
        form_heading {
          text
        }
        title_tag
        meta_description
        footer_text {
          html
        }
        body {
          ... on PrismicHoDataBodySpeakers {
            id
            slice_type
            items {
              bio {
                html
              }
              image {
                thumbnails {
                  cropped {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
              name {
                text
              }
              position_title
            }
            primary {
              heading {
                text
              }
              background_colour
            }
          }
          ... on PrismicHoDataBodyTextImage {
            id
            slice_type
            primary {
              heading {
                text
              }
              background_colour
              featured_image {
                gatsbyImageData(layout: FULL_WIDTH)
                alt
              }
              lightbox_image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
              cta_label
              description {
                html
              }
              bio {
                html
              }
              name {
                text
              }
              position_title
            }
          }
        }
      }
    }
  }
`;
